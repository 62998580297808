<!--
 * @Author: Jerry
 * @Date: 2021-01-12 09:32:30
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-26 16:19:17
 * @Description: 订单详情
-->
<template>
  <div>
    <top></top>
    <div class="contain clearfix">
      <div class="steps">
        <el-alert v-if="basicInfo.orderStatus == '2'" title="订单已取消" type="error" center>
        </el-alert>
        <el-alert v-else-if="basicInfo.orderStatus == '8'" title="交易已取消" type="error" center>
        </el-alert>
        <el-alert v-else-if="basicInfo.orderStatus == '6'" title="签收单审核失败" type="error" center>
        </el-alert>
        <el-steps v-else :active="orderStatus" finish-status="success" align-center>
          <el-step title="订单待确认"></el-step>
          <el-step title="待发货"></el-step>
          <el-step title="待收货"></el-step>
          <el-step title="签收单审核中"></el-step>
          <el-step title="签收单审核通过"></el-step>
          <el-step title="交易完成"></el-step>
        </el-steps>
      </div>
      <div class="co_primary">
        <el-button type="primary" size="small" @click="goBack()">返回</el-button>
        <span class="FBold F20 Mlf20">订单详情</span>
        <el-card class="box-card Mtp15 Mbm20">
          <div class="Mbm20">
            <span class="Mrt70">订单编号：
              <span>{{ basicInfo.orderNo }}</span>
            </span>
            <span>订单创建时间：
              <span>
                {{ formatDate(basicInfo.orderTime * 1000) }}
              </span>
            </span>
          </div>
          <!-- 收货人信息 -->
          <div class="Mbm20">
            <div class="shouhuo Mbm10 FBold">收货人信息</div>
            <div style="display: flex; align-items: center">
              <div style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                " class="bg-orange co_fff wid40 Plf25 Prt25 Ptp5 Pbm5 Mrt20">
                <span>{{ basicInfo.consignee }}</span>
              </div>
              <div>
                <span class="Mrt15">{{ basicInfo.address }}</span>
                <span>{{ basicInfo.mobile }}</span>
              </div>
            </div>
          </div>
          <!-- 送货清单 -->
          <div class="Mbm20">
            <div class="Mbm10 FBold">送货清单</div>
            <div class="bg_f4f4 Plf20 Prt20 Ptp20 Pbm30 clearfix">
              <div class="floatL het100 textM">
                <div class="Mtp15">
                  <img style="vertical-align: middle" class="het70 wid70 Prt10" slot="img" :src="basicInfo.goodsLogo" />
                  <div class="goodsTitle">
                    <div>
                      <span class="FBold" style="font-size:18px">货物名称</span><br>
                      <span>{{ basicInfo.goodsTitle }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="floatR het100 money">
                <div class="Prt80">
                  <div>
                    <span class=" FBold" style="font-size:18px">规格</span><br>
                    <span v-if="basicInfo.goodsSellName">{{ basicInfo.goodsSellName }}</span>
                    <div style="height:20px" v-else></div>
                  </div>
                </div>

                <div class="co_f00 FBold Prt80">
                  <div>
                    <span class=" FBold" style="font-size:18px;color:black">单价</span><br>
                    <span>￥{{ basicInfo.goodsPrice }}</span>
                  </div>
                </div>

                <div>
                  <span class=" FBold" style="font-size:18px">数量</span><br>
                  <span>X{{ basicInfo.goodsCount }}</span>
                </div>

              </div>
            </div>
          </div>
          <!-- 付款状态 -->
          <div>
            <div class="Mbm20 FBold">
              付款状态
              <span class="Mlf10 F14" v-if="basicInfo.payStatus == '2'">已付款</span>
              <span class="Mlf10 F14" v-else>未付款</span>
            </div>
          </div>
          <!-- 物流方式 -->
          <div>
            <div class="Mbm10 FBold">物流信息</div>
            <div>
              <el-table :data="tableData" style="width: 100%" border size="mini">
                <el-table-column prop="freightType" label="货运类型" width="150">
                  <template slot-scope="scope">
                    {{ freightType[scope.row.freightType] }}
                  </template>
                </el-table-column>
                <el-table-column prop="waybillNumber" label="订单号" width="280">
                  <template slot-scope="scope">
                    <!-- 如果是第三方和快递，显示为空 -->
                    <span v-if="scope.row.freightType == '2' || scope.row.freightType == '3'"></span>
                    <!-- 如果是网络货运平台，直接显示 waybillNumber -->
                    <span v-else>{{ scope.row.waybillNumber }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="webTransportNo" label="运单号">
                  <template slot-scope="scope">
                    <!-- 如果是第三方和快递，显示为 waybillNumber -->
                    <span v-if="scope.row.freightType == '2' || scope.row.freightType == '3'">
                      {{ scope.row.waybillNumber }}
                    </span>
                    <!-- 如果是网络货运平台，直接显示 webTransportNo -->
                    <span v-else>{{ scope.row.webTransportNo }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="action" label="操作">
                  <template slot-scope="scope">
                    <el-button @click="findLogisticsMessage(scope.row)" type="primary" size="mini">查看详情</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- <div class="Mbm10 Mrt20 F14" style="display:inline-block">送货方式</div>
            <div  style="display:inline-block">
              <div
                style="display: flex;justify-content: center;align-items: center;"
                class="bg-orange co_fff wid40 Plf25 Prt25 Ptp5 Pbm5 Mrt20"
              >
                <span>{{ freightType }}</span>
              </div>
            </div> -->
            <!-- <div>其他</div> -->
            <!-- <div class="Mbm10">
              <logistics-information
                ref="logisticsInformation"
              ></logistics-information>
            </div> -->
          </div>
        </el-card>
      </div>
      <div class="moneyCount Mtp15 Mbm40 F14 floatR">
        <!-- 供应商 -->
        <div class="Mbm5" v-if="userInfo.roleType == '1'">总商品金额：￥{{ basicInfo.orderSupplierMoney }}</div>
        <!-- 采购商 -->
        <div class="Mbm5" v-if="userInfo.roleType == '2'">总商品金额：￥{{ basicInfo.orderMoney }}</div>
        <div v-if="basicInfo.payTime !== -1" class="Mbm5">
          支付时间：{{ formatDate(basicInfo.payTime * 1000) }}
        </div>
        <div v-if="basicInfo.deliveryTime !== -1">
          发货时间：{{ formatDate(basicInfo.deliveryTime * 1000) }}
        </div>
      </div>
    </div>
    <bottom class="bottom"></bottom>
    <!-- 物流信息 -->
    <logisticsInfo ref="logisticsInfo"></logisticsInfo>
    <!-- 网络货运平台 物流信息 地图打点 -->
    <logisticsInfoByMap ref="logisticsInfoByMap"></logisticsInfoByMap>
    <!-- 第三方物流 -->
    <logisticsInfoByMap2 ref="logisticsInfoByMap2"></logisticsInfoByMap2>
    <TransportInfo :visible="visible" :tableData="tableDataDetails" :onClickClose="onClickClose" />
  </div>
</template>

<script>
import { findLogisticsList } from "@/api/product";
import top from "@/components/top.vue";
import Bottom from "@/components/bottom.vue";
import { formatDate, freightType } from "@/util/util";
import { getOrderDetail } from "@/api/order";
import { getTransportInfo } from "@/api/product";
import TransportInfo from "@/components/myOrder/transportInfo";
// import LogisticsInformation from "../../components/myOrder/logisticsInformation.vue";
import logisticsInfo from "../../components/myOrder/logisticsInfo";
import logisticsInfoByMap from "../../components/myOrder/logisticsInfoByMap";
import logisticsInfoByMap2 from "../../components/myOrder/logisticsInfoByMap2";
import { mapGetters } from "vuex";
export default {
  components: {
    top,
    Bottom,
    logisticsInfo,
    logisticsInfoByMap,
    logisticsInfoByMap2,
    TransportInfo
    // LogisticsInformation,
  },
  data() {
    return {
      tableData: [],
      freightType,
      query: {},
      // active: 1,
      basicInfo: {},
      logisticsMessage: {},
      orderStatus: 1,
      visible: false,
      tableDataDetails: []
    };
  },
  computed: { ...mapGetters(["userInfo"]) },
  watch: {},
  mounted() {
    if (this.$route.query) {
      this.$set(this, "query", this.$route.query);
    }
    this.getOrderDetail(this.query.orderId);
    // this.$refs.logisticsInformation.findLogisticsMessage(this.query.orderId);
  },
  methods: {
    formatDate: formatDate,
    goBack() {
      this.$router.go(-1);
    },
    getOrderDetail(orderId) {
      getOrderDetail({ orderId }).then(res => {
        this.$set(this, "basicInfo", res.data);
        if (this.basicInfo.orderStatus == "1") {
          this.orderStatus = 0;
        } else if (this.basicInfo.orderStatus == "3") {
          this.orderStatus = 1;
        } else if (this.basicInfo.orderStatus == "4") {
          this.orderStatus = 2;
        } else if (this.basicInfo.orderStatus == "5") {
          this.orderStatus = 3;
        } else if (this.basicInfo.orderStatus == "7") {
          this.orderStatus = 4;
        } else if (this.basicInfo.orderStatus == "9") {
          this.orderStatus = 5;
        } else {
          this.orderStatus = 0;
        }
        this.findLogisticsList(orderId);
      });
    },
    findLogisticsList(orderId) {
      findLogisticsList({
        orderId: orderId
      }).then(res => {
        if (res.success) {
          this.$set(this, "tableData", res.data);
        }
      });
    },
    // 查看快递 详细信息
    findLogisticsMessage(row) {
      let type = row.freightType;
      let id = row.id;
      if (type == 1) {
        // 网络货运平台
        getTransportInfo({
          transportNo: row.webTransportNo,
          logisticsId: row.id,
          plateNumber: row.plateNumber,
          waybillNumber: row.waybillNumber
        }).then(res => {
          console.log("getTransportInfo", res);
          if (res.code === 200) {
            this.tableDataDetails = res.data;
          }
        });
        this.visible = true;
      } else if (type == 2) {
        // 第三方物流
        this.$refs.logisticsInfoByMap2.showModal(id, row);
      } else if (type == 3) {
        // 物流
        this.$refs.logisticsInfo.showModal(id, row);
      }
    },
    onClickClose() {
      this.visible = false;
    }
  }
};
</script>

<style lang="scss" scope>
.steps {
  width: 800px;
  margin: 45px auto 30px;
}
.goodsTitle {
  display: inline-block;
  // height: 70px;
  width: 200px;
  vertical-align: middle;
  font-size: 14px;
}
.money {
  display: flex;
  align-items: center;
}
</style>
